/**actions required for Contract list api */

import { dynamicObject, ErrorInAPI, paginationQueryParam, SortAndPaginationModal } from '@models/common.model';
import { ContractCreateAPIRequest, ContractDetail, ContractListPaginationResponse } from '@models/contract.model';
import { createAction, props } from '@ngrx/store';

export const loadContracts = createAction(
  '[API] Load Contracts',
  props<{
    search?: string | '',
    pagination: paginationQueryParam;
    searchFilterObj?: { [key: string]: string | number };
  }>()
);

export const loadContractsSuccess = createAction(
  '[API] Load Contracts Success',
  props<{ contracts: ContractListPaginationResponse }>()
);


export const loadContractsError = createAction(
  '[API] Load Contracts Error',
  props<{ error: any }>()
);

export const contractDetail = createAction(
  '[API] Contract Detail',
  props<{ id: number }>()
);

export const contractDetailSuccess = createAction(
  '[API] Contract Detail Success',
  props<{ contract: ContractDetail }>()
);
export const contractDetailError = createAction(
  '[API] Contract Detail Error',
  props<{ error: any }>()
);

export const setActiveTab = createAction(
  '[API]Set Active Tabs',
  props<{ tab: number }>()
);


//Create Contract
export const addContract = createAction(
  '[API] Add Contract',
  props<{ contract: ContractCreateAPIRequest }>()
);

export const contractOperationMessage = createAction(
  '[API] Contract Operation Message',
  props<{ message: string }>()
);

export const addContractError = createAction(
  '[API] Add Contract Error',
  props<{ error: ErrorInAPI }>()
);

// Update contract details
export const updateContract = createAction(
  '[API] Update Contract',
  props<{
    id: string | number;
    contract: ContractCreateAPIRequest;
  }>()
);

export const updateContractError = createAction(
  '[API] Update Contract Error',
  props<{ error: ErrorInAPI }>()
);

export const updateContractSuccess = createAction(
  '[API] Update Contract Success',
  props<{ contract: ContractDetail }>()
);

// Contract search
export const loadContractWithSearch = createAction(
  '[API] Load Contract With Search', props<{ search?: string, pagination: SortAndPaginationModal, searchFilterObj?: dynamicObject }>()
);

export const loadContractWithSearchSuccess = createAction(
  '[API] Load Contract With Search Success',
  props<{ contracts: ContractListPaginationResponse }>()
);

/**Reset state */
export const resetStateAfterOperation = createAction(
  '[API] Reset State After Operation'
);

export const resetStateSuccess = createAction('[API] Reset State Success');

export const ContractActions = {
  loadContractsError,
  loadContractsSuccess,
  loadContracts,
  contractDetail,
  contractDetailSuccess,
  contractDetailError,
  addContract,
  addContractError,
  updateContract,
  updateContractSuccess,
  updateContractError,
  contractOperationMessage,
  resetStateAfterOperation,
  resetStateSuccess,
  setActiveTab,
  loadContractWithSearch,
  loadContractWithSearchSuccess
};
